import { Controller } from "@hotwired/stimulus";

export class SegmentResetUserFormController extends Controller {
  constructor(props) {
    super(props);
    this.boundSubmitForm = this.submitForm.bind(this);
  }

  connect() {
    this.element.addEventListener("submit", this.boundSubmitForm);
  }

  disconnect() {
    this.element.removeEventListener("submit", this.boundSubmitForm);
  }

  submitForm(event) {
    if (analytics?.user()?.id()) {
      analytics.reset();
    }
  }
}
